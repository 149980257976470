import BaseService from "../BaseService";

class UserRoleService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * 修改角色状态
   */
  changeRoleStatus(params) {
    return this.http.post("enableRole", params);
  }

  /**
   * 保存角色
   */
  addRoleList(params) {
    return this.http.post("addUserRole", params);
  }

  /**
   * 保存角色
   */
  editRoleList(params) {
    return this.http.post("editUserRole", params);
  }

  /**
   * 删除角色
   */
  deleteRoleList(params) {
    return this.http.post("deleteUserRole", params);
  }

  /**
   * 获取角色列表
   */
  getRoleList(params) {
    return this.http.post("queryRoleInfoPageList", params);
  }

   /**
   * 复制角色
   */
   copyRole(params) {
    return this.http.post("copyUserRole", params);
  }

  /**
   * 删除角色用户
   */
  deleteRoleAccount(params) {
    return this.http.post("delAccountUserRole", params);
  }

  /**
   * 新增角色用户
   */
  addRoleAccount(params) {
    return this.http.post("addAccountUserRole", params);
  }

  /**
   * 获取角色下的用户列表
   */
  getRoleAccount(params) {
    return this.http.post("getRoleAccountList", params);
  }

  /**
   * 获取组织用户树
   */
  getDeptTree(params) {
    return this.http.post("queryDeptAppTree", params);
  }

  /**
   * 获取权限集合
   */
  getRoleMenuTree(params) {
    return this.http.post("queryRoleMenuTree", params);
  }

  /**
   * 保存权限
   */
  saveSetModel(params) {
    return this.http.post("saveSetAccessModel", params);
  }

   /**
   * 获取权限菜单类型
   */
   getRoleLeftTree(params) {
    return this.http.post("queryMenuTree", params);
  }
}

export default UserRoleService;
