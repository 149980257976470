<!--
 * @Author: Do not edit
 * @Date: 2021-03-25 11:52:12
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-01 15:13:50
 * @Description: Do not edit
 * @FilePath: \user\src\components\top-nav-menu\select-item.vue
-->
<template>
  <div
    class="select-item"
    :class="style"
    :style="isChildren ? '': 'border: 1px solid #E8ECF2;border-radius: 4px;'"
  >
    <div class="label">
      {{ data.name }}
    </div>
    <div class="right">
      切换
    </div>
  </div>
</template>

<script>
export default {
  name: "select-item",
  props: {
    data: {
      type: Object
    },
    selected: {
      type: Boolean
    },
    isChildren: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      if (!this.selected) {
        return "";
      }
      return this.isChildren ? "selectChildren" : "select";
    }
  }
};
</script>

<style lang="scss" scoped>

  .select-item {
    height: 36px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .label {
      font-weight: 400;
      background-color: transparent;
      color: #636C78;
      font-size: 12px;
      margin-left: 19px;
    }
    .right {
      font-size: 12px;
      font-weight: 400;
      color: #3e90fe;
      margin-right: 9px;
      display: none;
    }
    &:hover {
      .right {
        display: block;
      }
    }
  }

  .select {
    background: #3e90fe;
    .label {
      color: #FFFFFF;
    }
  }

  .selectChildren {
    .label {
      color: #3e90fe;
    }
    background-color: #ffffff;
  }

</style>
