const routes = [
  {
    path: "/userRole",
    component: () => import(/* webpackChunkName:"setting" */ "@/views/userRole/index"),
    meta: {
      title: "角色权限",
      keepAlive: false,
      fullPage: false
    }
  },
  {
    path: "/setRole",
    component: () => import(/* webpackChunkName:"setting" */ "@/views/userRole/setRole.vue"),
    meta: {
      title: "设置权限",
      keepAlive: false,
      fullPage: false
    }
  }
];

export default routes;
